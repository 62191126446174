import {
  BasicModal,
  Button,
  Card,
  SelectLocationModal,
  StatusBar,
} from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import styles from "src/pages/Menu/styles.module.scss";
import {
  getAccountPath,
  getCartPath,
  getDealPath,
  getItemPath,
  getSignInPath,
} from "src/Router/routes";
import { captureManualSentryException } from "src/common/sentry";
import { selectCategoriesWithAtLeastOneDisplayedItem } from "src/state/category/utils";
import { LOYALTY_TYPE } from "src/common/types/Loyalty";
import {
  faBagShopping,
  faCircleInfo,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  logClickMenuDisclaimerToAnalytics,
  logMenuOrderNowClickedToAnalytics,
  logPointsTooltipClickedToAnalytics,
  logUpdateOrderTypeToAnalytics,
  logViewCartClickedToAnalytics,
  logViewItemClickedToAnalytics,
} from "src/common/analytics";
import { selectDisplayedItemsFromState } from "src/state/item/utils";
import { selectDealsWithDisplayedItems } from "src/state/deal/utils";
import { CategoriesSlider } from "src/pages/Menu/CategoriesSlider/CategoriesSlider";
import classNames from "classnames";
import { ORDER_TYPE } from "src/state/order/types";
import { updateOrderTypeAction } from "src/state/orderType/actions";
import {
  isNowWithinDealScheduleHours,
  isRestaurantCurrentlyOpen,
} from "src/common/date";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getAddressStringFromLocationEntity } from "src/common/address";

export const Menu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartDivRef = useRef<HTMLDivElement>(null);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const selectedLocation = useSelector(
    (state: State) => state.location.selectedLocation,
  );
  const selectedOrderType = useSelector(
    (state: State) => state.orderType.selectedOrderType,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const items = useSelector(selectDisplayedItemsFromState);
  const displayedCategories = useSelector(
    (state: State) =>
      restaurant && selectCategoriesWithAtLeastOneDisplayedItem(state, items),
  );
  const deals = useSelector((state: State) => {
    if (restaurant && displayedCategories) {
      const allDeals = Object.values(
        selectDealsWithDisplayedItems(state, displayedCategories, items)[
          restaurant.id
        ],
      );

      const dealsCurrentlyInSchedule = allDeals.filter((eachDeal) =>
        isNowWithinDealScheduleHours(eachDeal.dealSchedule),
      );

      return dealsCurrentlyInSchedule;
    }
  });
  const randomDeliveryTime = Math.floor(Math.random() * (35 - 20 + 1)) + 20;
  const estDeliveryTime =
    (restaurant?.restaurantSettings.minPreparationTime ?? 0) +
    randomDeliveryTime;

  const isOrderBannerDisplayed = useSelector(
    (state: State) => state.orderBanner.isDisplayed,
  );
  const cart = useSelector((state: State) => state.cart);

  const cartAsArray = useMemo(() => Object.values(cart), [cart]);
  const cartSize = useMemo(() => {
    return cartAsArray.length;
  }, [cartAsArray]);
  const cartSubTotal = useMemo(
    () => cartAsArray && cartAsArray.reduce((a, v) => a + v.totalPrice, 0),
    [cartAsArray],
  );

  const categoriesArray = useMemo(
    () =>
      restaurant &&
      displayedCategories &&
      Object.values(displayedCategories[restaurant.id]).sort(
        (a, b) => a.sortOrder - b.sortOrder,
      ),
    [restaurant, displayedCategories],
  );

  const [isPointsModalVisible, setIsPointsModalVisible] = useState(false);
  const [isLocationSelectorVisible, setIsLocationSelectorVisible] =
    useState(false);
  const [isFloatingButtonVisible, setIsFloatingButtonVisible] = useState(false);

  const headerPixelHeight = useMemo(() => {
    let basePixelHeight = 80;

    if (isOrderBannerDisplayed) {
      basePixelHeight += 80;
    }

    if (
      selectedLocation &&
      !isRestaurantCurrentlyOpen(selectedLocation.hoursOfOperation)
    ) {
      basePixelHeight += 55;
    }

    return basePixelHeight;
  }, [isOrderBannerDisplayed, restaurant, selectedLocation]);

  const goToCart = useCallback(() => {
    navigate(getCartPath());

    logViewCartClickedToAnalytics(customer?.id, cartSize, cartSubTotal);
  }, [navigate, customer, cartSize, cartSubTotal]);

  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });

  useEffect(() => {
    if (cartSize > 0 && restaurant) {
      document.getElementById("menu-container")?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (cartDivRef.current) {
        const targetPosition = cartDivRef.current.getBoundingClientRect();

        const isOffscreen = targetPosition.bottom < 0;

        if (isOffscreen) {
          setIsFloatingButtonVisible(true);
        } else {
          setIsFloatingButtonVisible(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!restaurant || !categoriesArray || !deals) {
    captureManualSentryException(
      new Error("restaurant or categoriesArray or deals is undefined in Menu"),
    );
    return <div />;
  }

  return (
    <div className={styles.overflowContainer}>
      <div
        data-testid="custom-home-page"
        className={styles.customHomePageContainer}
        style={{
          height: `calc(100vh - ${headerPixelHeight}px)`,
        }}
      >
        <img
          src={restaurant.coverPhotoUrl}
          className={styles.customHomePageImage}
          style={{
            height: `calc(100vh - ${headerPixelHeight}px)`,
          }}
          alt="Custom Home Page Image"
        />
        <Button
          className={styles.customHomePageButton}
          onClick={() => {
            document
              .getElementById("menu-container")
              ?.scrollIntoView({ behavior: "smooth" });
            logMenuOrderNowClickedToAnalytics(customer?.id);
          }}
          testId="custom-home-page-button"
        >
          <h1 className={styles.customHomePageButtonText}>
            {restaurant.restaurantSettings.isOnlineOrderingEnabled
              ? "Order Now"
              : "View Menu"}
          </h1>
        </Button>
      </div>
      <div
        className={styles.Menu}
        data-testid="menu-container"
        id="menu-container"
        style={{
          scrollMarginTop: isMobile ? "35px" : "0px",
        }}
      >
        <div className={styles.menuHeader}>
          {customer ? (
            <h1 className={styles.welcomeCustomer} data-testid="welcome-name">
              Welcome, {customer.firstName}!<br />
              Hungry?
            </h1>
          ) : (
            <div />
          )}
          {restaurant.restaurantSettings.isOnlineOrderingEnabled ? (
            <div className={styles.headerRight} ref={cartDivRef}>
              <Link
                className={styles.accountLink}
                to={customer ? getAccountPath() : getSignInPath()}
                data-testid={"menu-account-icon"}
              >
                <FontAwesomeIcon className={styles.icon} icon={faCircleUser} />
              </Link>
              <Link
                to={getCartPath()}
                className={styles.iconLink}
                onClick={() => {
                  logViewCartClickedToAnalytics(
                    customer?.id,
                    cartSize,
                    cartSubTotal,
                  );
                }}
              >
                {cartSize > 0 ? (
                  <div className={styles.checkoutButton}>
                    <p
                      data-testid="menu-checkout-text"
                      className={styles.checkoutText}
                    >{`Checkout (${cartSize})`}</p>
                  </div>
                ) : (
                  <div data-testid={"menu-cart-icon"}>
                    <FontAwesomeIcon
                      className={styles.icon}
                      icon={faBagShopping}
                    />
                  </div>
                )}
              </Link>
            </div>
          ) : (
            <div />
          )}
        </div>
        {customer &&
          restaurant.loyalty.type !== LOYALTY_TYPE.NONE &&
          restaurant.restaurantSettings.isOnlineOrderingEnabled && (
            <div className={styles.customerPointsContainer}>
              <div
                className={styles.customerPointsTextRow}
                onClick={() => {
                  logPointsTooltipClickedToAnalytics(customer.id);
                  setIsPointsModalVisible(true);
                }}
              >
                <p
                  className={styles.customerPointsText}
                  data-testid="customer-points-text"
                >
                  {customer.points} available points
                </p>
                <FontAwesomeIcon
                  className={styles.circleInfoIcon}
                  icon={faCircleInfo}
                />
              </div>
              <StatusBar
                className={styles.pointsBar}
                indicatorPosition={customer.points}
              />
            </div>
          )}
        {selectedLocation && restaurant.locations.length > 1 && (
          <div
            className={styles.selectedLocationContainer}
            data-testid="location-container"
          >
            <h5
              className={styles.selectedLocationText}
              data-testid="location-text"
            >
              {getAddressStringFromLocationEntity(selectedLocation)}
            </h5>
            <h5
              data-testid="edit-location"
              className={styles.editLocationText}
              onClick={() => setIsLocationSelectorVisible(true)}
            >
              Edit
            </h5>
          </div>
        )}
        {restaurant.restaurantSettings.isOnlineOrderingEnabled === false ? (
          <h1 className={styles.titleHeader} data-testid="order-now-text">
            Menu
          </h1>
        ) : restaurant.restaurantSettings.deliverySettings.enabled ? (
          <div className={styles.toggleAndDeliveryContainer}>
            <div className={styles.toggleContainer}>
              <h4
                className={classNames(styles.leftToggleInternal, {
                  [styles.leftToggleActive]:
                    selectedOrderType === ORDER_TYPE.PICKUP,
                })}
                onClick={() => {
                  updateOrderTypeAction(ORDER_TYPE.PICKUP)(dispatch);
                  logUpdateOrderTypeToAnalytics(
                    customer?.id,
                    ORDER_TYPE.PICKUP,
                    "MENU",
                  );
                }}
                data-testid="pickup-toggle"
              >
                Pickup
              </h4>
              <h4
                className={classNames(styles.rightToggleInternal, {
                  [styles.rightToggleActive]:
                    selectedOrderType === ORDER_TYPE.DELIVERY,
                })}
                onClick={() => {
                  updateOrderTypeAction(ORDER_TYPE.DELIVERY)(dispatch);
                  logUpdateOrderTypeToAnalytics(
                    customer?.id,
                    ORDER_TYPE.DELIVERY,
                    "MENU",
                  );
                }}
                data-testid="delivery-toggle"
              >
                Delivery
              </h4>
            </div>
            {selectedOrderType === ORDER_TYPE.DELIVERY && (
              <span className={styles.deliveryTime} data-testid="delivery-time">
                {`Est. ${estDeliveryTime} min`}
              </span>
            )}
          </div>
        ) : (
          <h1 className={styles.titleHeader} data-testid="order-now-text">
            Order Now
          </h1>
        )}
        {!restaurant.isOwnerManaged && (
          <p className={styles.createdBy} data-testid="menu-disclaimer">
            {`Created by `}
            <span
              className={styles.createdByLink}
              onClick={() => {
                logClickMenuDisclaimerToAnalytics(customer?.id);
                window.open("https://www.joinplatter.com", "_blank");
              }}
            >
              Platter
            </span>
          </p>
        )}
        <CategoriesSlider deals={deals} categoriesArray={categoriesArray} />
        {deals.length > 0 && (
          <div
            className={styles.dealsContainer}
            id={"deals-container"}
            data-testid="deals-container"
          >
            <h2 className={styles.sectionHeader}>Deals</h2>
            <div className={styles.itemsGrid}>
              {deals.map((deal) => (
                <Card
                  testId={`deal-card-${deal.id}`}
                  link={getDealPath(deal.id)}
                  key={deal.id}
                  image={deal.imageUrl}
                  title={deal.name}
                  description={
                    deal.description.length > 0 ? deal.description : undefined
                  }
                />
              ))}
            </div>
          </div>
        )}
        <div className={styles.categoriesContainer}>
          {categoriesArray.map((category) => (
            <div
              className={styles.categoryContainer}
              data-testid={`category-container-${category.id}`}
              key={category.id}
              id={category.id}
            >
              <h2 className={styles.sectionHeader}>{category.name}</h2>
              <div className={styles.itemsGrid}>
                {Object.values(items[category.id])
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((item) => (
                    <Card
                      testId={`item-card-${item.id}`}
                      link={getItemPath(category.id, item.id)}
                      key={item.id}
                      image={item.hasImage ? item.imageURL : undefined}
                      title={item.name}
                      description={item.description}
                      price={item.price}
                      onClick={() => {
                        logViewItemClickedToAnalytics(
                          customer?.id,
                          item.id,
                          item.name,
                        );
                      }}
                    />
                  ))}
              </div>
            </div>
          ))}
          {isFloatingButtonVisible &&
            (cartSize > 0 ? (
              <div className={styles.floatingCheckoutButton} onClick={goToCart}>
                <p
                  className={styles.floatingCheckoutText}
                >{`Checkout (${cartSize})`}</p>
              </div>
            ) : (
              <div
                data-testid={"menu-cart-icon"}
                className={styles.floatingCartButton}
                onClick={goToCart}
              >
                <FontAwesomeIcon
                  className={styles.floatingCart}
                  icon={faBagShopping}
                />
              </div>
            ))}
        </div>
        <BasicModal
          height="275"
          testId="points-modal"
          isModalVisible={isPointsModalVisible}
          title={"Points"}
          message={`Points are earned by placing orders from the menu. For every $1 you spend, you get 100 points. Every ${
            (restaurant.loyalty.pointsSystemAmountSpend as number) * 100
          } points you earn, you get $${
            restaurant.loyalty.pointsSystemAmountEarn
          } off your next order. Eat up!`}
          onConfirm={() => setIsPointsModalVisible(false)}
          onClickOutside={() => setIsPointsModalVisible(false)}
        />
        <SelectLocationModal
          testId="menu-select-location-modal"
          isOpen={isLocationSelectorVisible}
          onClose={() => setIsLocationSelectorVisible(false)}
        />
      </div>
    </div>
  );
};
