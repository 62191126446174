import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TextInput, Button, Image } from "src/components";
import styles from "src/pages/SignIn/styles.module.scss";
import { State } from "src/state/state";
import {
  getForgotPasswordPath,
  getMenuPath,
  getSignUpPath,
} from "src/Router/routes";
import { isValidEmailAddress } from "src/common/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import { loginUserInAWSAction } from "src/state/auth/actions";
import { getCustomerFromDatabaseAction } from "src/state/customer/actions";
import { captureManualSentryException } from "src/common/sentry";
import classNames from "classnames";
import {
  logLogInFailureToAnalytics,
  logLogInInitiatedToAnalytics,
  logLogInSuccessToAnalytics,
} from "src/common/analytics";
import { useScrollToTop } from "src/common/useScrollToTop";
import { useDesign } from "src/common/getDesign";
import * as Sentry from "@sentry/react";
interface SignInFieldsErrors {
  emailAddress: string | undefined;
  password: string | undefined;
}

export const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const design = useDesign();
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorBox, setErrorBox] = useState<string | undefined>(undefined);

  const [errorMessages, setErrorMessages] = useState<SignInFieldsErrors>({
    emailAddress: undefined,
    password: undefined,
  });

  const areFieldsValid = useCallback(() => {
    const newErrorMessages = {
      ...errorMessages,
    };

    if (emailAddress.trim() === "") {
      newErrorMessages.emailAddress = "Please enter an email address.";
      logLogInFailureToAnalytics("FIELDS_MISSING");
    }

    if (
      emailAddress.trim() !== "" &&
      !isValidEmailAddress(emailAddress.trim())
    ) {
      newErrorMessages.emailAddress = "Please enter a valid email address.";
      logLogInFailureToAnalytics("INVALID_EMAIL");
    }

    if (password.trim() === "") {
      newErrorMessages.password = "Please enter a password.";
      logLogInFailureToAnalytics("FIELDS_MISSING");
    }

    for (const key in newErrorMessages) {
      if (newErrorMessages[key] !== undefined) {
        setErrorMessages(newErrorMessages);
        return false;
      }
    }

    return true;
  }, [emailAddress, password, errorMessages]);

  const loginAndGetCustomer = useCallback(async () => {
    if (restaurant) {
      try {
        setIsLoading(true);

        // Login user and fetch restaurant information from db
        const user = await loginUserInAWSAction(
          emailAddress.trim(),
          password.trim(),
        )(dispatch);

        const customerId = user.response.userId;

        const { response: customer } =
          await getCustomerFromDatabaseAction(customerId)(dispatch);

        logLogInSuccessToAnalytics(customerId);

        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate(getMenuPath());
        }

        Sentry.setContext("user_info", {
          restaurantId: restaurant.id,
          restaurantName: restaurant.restaurantName,
          customerId: customer.id,
          customerName: `${customer.firstName} ${customer.lastName}`,
        });

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        const errorMessage = typeof e === "string" ? e : (e as Error).message;

        if (errorMessage.includes("Incorrect username or password")) {
          logLogInFailureToAnalytics("INCORRECT_EMAIL_OR_PASSWORD");
          setErrorBox("Incorrect email or password.");
        } else {
          logLogInFailureToAnalytics("UNKNOWN_ERROR", errorMessage);
          captureManualSentryException(e as Error);
          setErrorBox("Something went wrong. Please try again.");
        }
      }
    }
  }, [emailAddress, password, restaurant, redirectUrl, dispatch, navigate]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is not defined in SignIn"),
    );

    return <div />;
  }

  return (
    <div className={styles.SignIn} data-testid="sign-in-container">
      <Image src={restaurant.logoUrl} alt="logo" className={styles.logo} />
      <h1
        className={styles.headingText}
      >{`Sign Into Your ${restaurant.restaurantName} Account`}</h1>
      <TextInput
        className={classNames(styles.input, styles.emailInput)}
        testId="email-address-input"
        label="Email Address"
        type="email"
        autoComplete="email"
        value={emailAddress}
        onChangeText={(newText) => {
          setEmailAddress(newText);
          setErrorMessages({
            ...errorMessages,
            emailAddress: undefined,
          });
        }}
        placeholder="Enter your email address..."
        errorMessage={errorMessages.emailAddress}
      />
      <TextInput
        className={styles.input}
        testId="password-input"
        label="Password"
        type="password"
        autoComplete="current-password"
        value={password}
        onChangeText={(newText) => {
          setPassword(newText);
          setErrorMessages({
            ...errorMessages,
            password: undefined,
          });
        }}
        placeholder="Enter your password..."
        errorMessage={errorMessages.password}
      />
      <Link
        data-testid="forgot-password-link"
        className={styles.forgotPassword}
        to={
          redirectUrl
            ? getForgotPasswordPath(redirectUrl)
            : getForgotPasswordPath()
        }
      >
        <h4>Forgot Password?</h4>
      </Link>
      {errorBox && (
        <div className={styles.errorBox}>
          <FontAwesomeIcon
            className={styles.errorIcon}
            icon={faTriangleExclamation}
          />
          <p data-testid="error-box-text" className={styles.errorBoxText}>
            {errorBox}
          </p>
        </div>
      )}
      {isLoading ? (
        <ReactLoading
          type="spin"
          color={design.buttonColor}
          height={40}
          width={40}
        />
      ) : (
        <Button
          className={styles.signInButton}
          testId="sign-in-button"
          onClick={() => {
            setErrorBox(undefined);
            logLogInInitiatedToAnalytics();

            if (areFieldsValid()) {
              loginAndGetCustomer();
            }
          }}
        >
          <h3 className={styles.buttonText}>Sign In</h3>
        </Button>
      )}
      <p className={styles.noAccountText}>
        {"Don't have an account? "}
        <Link
          to={redirectUrl ? getSignUpPath(redirectUrl) : getSignUpPath()}
          className={styles.signUpLink}
          data-testid="new-account-link"
        >
          Join Now
        </Link>
      </p>
    </div>
  );
};
