import { roundToNearestCent } from "src/common/price";

export const getServiceFee = (
  subtotal: number,
  totalBeforeServiceFee: number,
  isDelivery: boolean,
  isOwnerManaged: boolean,
) => {
  if (isDelivery) {
    return 1.25;
  }

  let baseServiceFee = 1.25;

  if (subtotal > 20) {
    baseServiceFee += Math.ceil((subtotal - 20) / 20);
  }

  if (!isOwnerManaged) {
    const totalPlusBaseServiceFee = totalBeforeServiceFee + baseServiceFee;

    const paymentProcessingFee = getPaymentProcessingFeeWithCardIncluded(
      totalPlusBaseServiceFee,
    );

    baseServiceFee += paymentProcessingFee;
  }

  return roundToNearestCent(baseServiceFee);
};

export const getPaymentProcessingFeeWithCardIncluded = (
  totalInDollars: number,
) => {
  const constantStripeFee = 0.3;
  const constantStripePercentage = 0.029;
  return (
    (totalInDollars + constantStripeFee) / (1 - constantStripePercentage) -
    totalInDollars
  );
};

export const getServiceFeeForCateringRequest = (subtotal: number) => {
  const achFee = 5;
  const fivePercentOfSubtotal = roundToNearestCent(subtotal * 0.05);

  if (fivePercentOfSubtotal > 80) {
    return 80 + achFee;
  }

  return fivePercentOfSubtotal + achFee;
};
